import { useState } from 'react';
import { Box, List, ListItem, SwipeableDrawer, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/images/webfix-studio-logo-918x250.png';

export default function NavBar({pageTitle}) {
    const [toggleDrawer, setToggleDrawer] = useState(false)

    const handleDrawer = () => {
        setToggleDrawer(value => !value)
    }

    return (
        <>
            <Box className='navbar' component={"nav"}>
                <Link to={'/'}>
                    <img className='logo' src={logo} alt='Webfix Studio Logo'/>
                </Link>
                <MenuIcon onClick={handleDrawer} />
            </Box>
            <SwipeableDrawer
                anchor='right'
                open={toggleDrawer}
                onClose={handleDrawer}
                onOpen={handleDrawer}
            >
                <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={handleDrawer}
                >
                    
                    <List>
                        <Link to={'/'} className='list-btn'>
                            <ListItem>
                                <Typography variant='body1' component='h3'>{pageTitle}</Typography>
                            </ListItem>
                        </Link>
                        {/* <Link to={'/services'} className='list-btn'>
                            <ListItem divider>
                                <ListItemButton sx={{height: '2.1rem'}}>
                                    <ListItemText primary='Service' />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        <Link to={'/work'} className='list-btn'>
                            <ListItem divider>
                                <ListItemButton sx={{height: '2.1rem'}}>
                                    <ListItemText primary='Work' />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        <Link to={'/about-us'} className='list-btn'>
                            <ListItem divider>
                                <ListItemButton sx={{height: '2.1rem'}}>
                                    <ListItemText primary='About Us' />
                                </ListItemButton>
                            </ListItem>
                        </Link> */}
                    </List>
                </Box>
            </SwipeableDrawer>
        </>
    )
}
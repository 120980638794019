import { useState } from 'react';
import Banner from '../components/Banner';
import BannerImage from '../assets/images/top-desk-view.jpg'
import OurServices from '../components/OurServices';
import 'animate.css'

export const listOfServices = [
  {id: 1, title: 'Website Design and Development', text:'Unlock your online potential with our Website Design and Development service. We create stunning, user-friendly websites that leave a lasting impression. Let us bring your vision to life and elevate your digital presence.'},
  {id: 2, title: 'E-commerce Development', text: "Empower your online business with our E-commerce Development service. We create secure, user-friendly platforms that drive sales and elevate your brand. Let's unlock your e-commerce potential together!"},
  {id: 3, title: 'Front-end Development', text: 'Unleash creativity and interactivity with our Front-end Development service. Our expert team crafts engaging web experiences that captivate users and boost your online presence. Elevate your website or app with our cutting-edge solutions today!'},
  {id: 4, title: 'Web Application Development', text: 'Experience innovation with our Web Application Development service. We create custom, powerful solutions to streamline your business and enhance user experiences. Maximize your potential with us now!'},
  {id: 5, title: 'Search Engine Optimization (SEO)', text: 'Elevate your online visibility with our SEO service. Our experts optimize your website to rank higher in search results and drive organic traffic. Reach new heights in the digital world with us!'},
];

export default function HomePage() {

  // eslint-disable-next-line
  const [bannerImage, setBannerImage] = useState(BannerImage);
  // eslint-disable-next-line
  const [bannerMainText, setPageTitle] = useState("WebFix Studio");
  // eslint-disable-next-line
  const [bannerSubText, setBannerText] = useState("Custom web design to fit your brand and style");

  return (
    <main>
      <section>
        <Banner bannerMainText={bannerMainText} bannerSubText={bannerSubText} bannerImage={bannerImage}></Banner>
      </section>
      <section>
        <OurServices listOfServices={listOfServices}></OurServices>
      </section>
    </main>
  )
}
import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import NavBar from './components/NavBar';
import HomePage from './pages/HomePage';
import Footer from './components/Footer';
// import Services from './pages/Services';
// import Work from './pages/Work';
// import AboutUs from './pages/AboutUs';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const App = () => {

  // eslint-disable-next-line
  const [pageTitle, setPageTitle] = useState("WebFix Studio");
  
  return (
      <BrowserRouter>
        <NavBar pageTitle={pageTitle}/>
        <Routes>
          <Route path='/' element={<HomePage />} />
          {/* <Route path='/services' element={<Services />} /> */}
          {/* <Route path='/work' element={<Work />} /> */}
          {/* <Route path='/about-us' element={<AboutUs />} /> */}
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
  );
};

export default App;
